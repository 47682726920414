import React, { useState, useEffect } from 'react';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme/theme';
import Header from './header/Header';
import Workarea from './workarea/Workarea';
import CssBaseline from '@mui/material/CssBaseline';
import NotificationsPane from './notifications/NotificationsPane';
import { useLocation } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import useAccount from './hooks/useAccount';

LicenseInfo.setLicenseKey('febb1cae698c78eb24770a8389cf04eeTz0xMDMzMzAsRT0xNzY0NzU2NzU0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

const App = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [runTour, setRunTour] = useState(false);
    const [steps, setSteps] = useState([]);
    const { handleCompleteTour, handleRunTour } = useAccount();
    const [tourStatus, setTourStatus] = useState('');
    const location = useLocation();
    useGoogleAnalytics();

    const handleHelpClick = () => {
        setSteps(handleRunTour());
        setRunTour(true);
    };

    const handleTourCallback = (data: CallBackProps) => {
        const { status } = data;
        setTourStatus(status);
    };

    useEffect(() => {
        setShowNotifications(false);

        setSteps((currentSteps) =>
            currentSteps.map((step) => ({
                ...step,
                disableBeacon: true
            }))
        );
    }, [location]);

    useEffect(() => {
        if (tourStatus === STATUS.FINISHED) {
            setRunTour(false);
            handleCompleteTour('start-screen-tour');
        }
    }, [tourStatus]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Joyride
                    steps={steps}
                    run={runTour}
                    callback={handleTourCallback}
                    continuous
                    scrollToFirstStep
                    showProgress
                    disableCloseOnEsc
                    disableOverlayClose
                    locale={{ last: 'Complete', open: 'Continue UI Tour' }}
                    styles={{
                        options: {
                            zIndex: 10000,
                            beaconSize: 36
                        },
                        tooltipContent: {
                            textAlign: 'left',
                            paddingBottom: '0px',
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        tooltipTitle: {
                            textAlign: 'left',
                            marginTop: '10px',
                            paddingBottom: '0px'
                        }
                    }}
                />
                <Header onToggleNotifications={() => setShowNotifications(!showNotifications)} handleHelpClick={handleHelpClick} />
                <Workarea handleHelpClick={handleHelpClick} />
                <NotificationsPane
                    isOpen={showNotifications}
                    onClose={() => setShowNotifications(false)}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;