import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { colours } from '../theme/constants.js';
import { useLocation, Link } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useNotifications from '../hooks/useNotifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import HelpIcon from '@mui/icons-material/Help';
import ToggleUserFeatureModal from './dialogs/ToggleUserFeatureModal.js';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SupportIcon from '@mui/icons-material/Support';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AccountProfile from './components/AccountProfile.js';
import InfoIcon from '@mui/icons-material/Info';
import useAccount from '../hooks/useAccount';

const iconButton = {
    color: '#FFFFFF',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: colours.secondary
    }
};

const HelpDropdownMenu = ({ handleHelpClick, location }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleHelpMenuClick = () => {
        handleClose();
        handleHelpClick();
    };

    return (
        <>
            <IconButton
                sx={iconButton}
                size="large"
                onClick={handleClick}
            >
                <HelpIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <ListSubheader>Get Support</ListSubheader>
                <MenuItem component="a" href="tel:01392447200">
                    <ListItemIcon>
                        <PhoneIcon fontSize="small" />
                    </ListItemIcon>
                    01392 447 200
                </MenuItem>
                <MenuItem component="a" href="mailto:support@newzapp.com">
                    <ListItemIcon>
                        <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    support@newzapp.com
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/raise-a-ticket" target="_blank">
                    <ListItemIcon>
                        <SupportIcon fontSize="small" />
                    </ListItemIcon>
                    Raise A Ticket
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/" target="_blank">
                    <ListItemIcon>
                        <LanguageIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Knowledge Base
                </MenuItem>
                <MenuItem component="a" href="https://help.newzapp.co.uk/training-videos" target="_blank">
                    <ListItemIcon>
                        <SubscriptionsIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Training Videos
                </MenuItem>
                <MenuItem component="a" href="https://newzapp.co.uk/why-newzapp/releases-bug-fixes-and-improvements/" target="_blank">
                    <ListItemIcon>
                        <RocketLaunchIcon fontSize="small" />
                    </ListItemIcon>
                    NewZapp Releases & Bug Fixes
                </MenuItem>
                {location === '/' && (
                    <MenuItem onClick={handleHelpMenuClick}>
                        <ListItemIcon>
                            <InfoIcon fontSize="small" />
                        </ListItemIcon>
                        UI Guided Tour
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

const Header = ({ onToggleNotifications, handleHelpClick }) => {
    const theme = useTheme();
    const location = useLocation();
    const { unreadNotificationsCount } = useNotifications();
    const { user } = useAccount();
    const [showFeatureDialog, setShowFeatureDialog] = useState(false);

    const hasNewReportsFeature = Boolean(user?.userFeature?.find(e => e.enabled === true && e.alias === 'reports'));

    const isActive = href => {
        if (href === '/') {
            return location.pathname === href;
        }

        const root = location.pathname.split('/')[1];

        return root ? `/${root}` === href : location.pathname.startsWith(href);
    };

    const button = {
        fontWeight: 100,
        fontSize: '1.15em',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#FFFFFF',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: colours.secondary
        },
        '&:focus': {
            color: '#FFFFFF'
        }
    };

    const activeButton = {
        color: '#FFFFFF',
        backgroundColor: colours.secondary
    };

    const getStyles = path => {
        const active = isActive(path);

        if (active) {
            return {
                ...button,
                ...activeButton
            };
        }

        return button;
    };

    return (
        <>
            <AppBar color="primary" sx={{ zIndex: theme.zIndex.drawer + 2, displayPrint: 'none' }}>
                <Toolbar>
                    <img src="/images/newzapp-motif.svg" style={{ maxHeight: 20, marginRight: 16 }} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Button className="start-screen" component={Link} variant="text" color="primary" to="/" sx={getStyles('/')}>Start</Button>
                        <Button component={Link} variant="text" color="primary" to="/editor" sx={getStyles('/editor')}>Create &amp; Send</Button>
                        <Button component={Link} variant="text" color="primary" to="/contacts" sx={getStyles('/contacts')}>Contacts</Button>
                        <Button component={Link} variant="text" color="primary" to="/automations" sx={getStyles('/automations')}>Automations</Button>
                        <Button component={Link} variant="text" color="primary" to="/reports" sx={getStyles('/reports')}>Reports</Button>
                        {hasNewReportsFeature && (
                            <Button component={Link} variant="text" color="primary" to="/newreports" sx={getStyles('/newreports')}>
                                <Badge badgeContent="BETA" color="warning">
                                    Reports
                                </Badge>
                            </Button>
                        )}
                    </Box>

                    <IconButton
                        color="primary"
                        onClick={onToggleNotifications}
                        sx={iconButton}
                        size="large"
                    >
                        <Badge
                            color="error"
                            badgeContent={unreadNotificationsCount}
                            max={99}
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <HelpDropdownMenu handleHelpClick={handleHelpClick} location={location.pathname} />

                    <AccountProfile handleFeatureDialog={() => setShowFeatureDialog(true)} />

                </Toolbar>
            </AppBar>
            <Toolbar />

            {showFeatureDialog && (
                <ToggleUserFeatureModal
                    shown={showFeatureDialog}
                    onHide={() => setShowFeatureDialog(false)}
                />
            )}
        </>
    );
};

export default Header;