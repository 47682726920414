import React, { forwardRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toolbar, AppBar, Tab, Tabs, Typography, Stack, Box, Slide, Paper, Chip, Alert, AlertTitle, Container, Button, IconButton, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Links from './views/Links';
import Hotspot from './views/Hotspot';
import { format } from 'date-fns';
import useApi from '../hooks/useApi';
import useAccount from '../hooks/useAccount';
import { printStyleSection, printStyleCover, printStyleBreakAfter, printStyleOverrides } from '../theme/constants';
import useSnackbar from '../hooks/useSnackbar';
import useTheme from '@mui/material/styles/useTheme';

const titleStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const Page = () => {
    const { id } = useParams();
    const { handleGet } = useApi();
    const { company, logo } = useAccount();
    const { showSnackbar } = useSnackbar();
    const [page, setPage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialising, setIsInitialising] = useState(true);
    const [tab, setTab] = useState(0);
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [publishedDate, setPublishedDate] = useState('-');
    const theme = useTheme();

    const handleLoadPage = async () => {
        const response = await handleGet(`pages/reports/${id}`);

        if (!response.ok) {
            showSnackbar('Error fetching Page', 'error');
            return;
        }

        setPage(await response.json());
    };

    const handleSetDocumentTitle = () => document.title = `Landing Page Report for '${page?.title}' - Published ${publishedDate}`;

    const handleUnSetDocumentTitle = () => document.title = 'NewZapp';

    useEffect(() => {
        if (!isInitialising) {
            setIsLoading(false);
            setPage(null);
        }

        handleLoadPage();
    }, [id]);

    useEffect(() => {
        setIsLoading(!Boolean(page));

        if (!page) {
            return;
        }
        else {
            setPublishedDate(format((new Date(page.publishedDateTime)), 'dd-MM-yyyy HHmm'));
            setIsInitialising(false);
        }

        window.addEventListener('beforeprint', () => handleSetDocumentTitle());
        window.addEventListener('afterprint', () => handleUnSetDocumentTitle());

        return () => {
            window.removeEventListener('beforeprint', () => handleSetDocumentTitle());
            window.removeEventListener('afterprint', () => handleUnSetDocumentTitle());
        };
    }, [page]);

    useEffect(() => {
        handleLoadPage();
    }, []);

    if (isLoading || isInitialising || !page) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{
                    top: 64,
                    zIndex: theme.zIndex.appBar - 1,
                    borderTop: 1,
                    borderColor: theme.palette.divider,
                    '@media print': {
                        position: 'relative',
                        top: 0,
                        display: 'block',
                        boxShadow: 'none',
                        borderBottom: 1
                    }
                }}
            >
                <Toolbar variant="dense" sx={{ overflow: 'hidden', pt: 2 }}>
                    <Stack>
                        <Typography component="h1" variant="h6" sx={titleStyle}>{page.name}</Typography>
                        <Typography component="h2" variant="body1" sx={titleStyle}>Title: {page.title}</Typography>
                    </Stack>
                </Toolbar>
                <Toolbar variant="dense">
                    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                        <Typography component="h2" variant="subtitle1" sx={titleStyle}>
                            {page.published ? `Published: ${format((new Date(page.publishedDateTime)), 'dd/MM/yyyy HH:mm')}` : 'Draft'}
                        </Typography>
                    </Box>
                    <Box sx={{ whiteSpace: 'nowrap', paddingLeft: 2 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography>Status: <Chip component="span" color={page.published ? 'success' : undefined} label={page.published ? 'Published' : 'Draft'} /></Typography>
                            <Typography>Total Opens: {page.totalOpens}</Typography>
                            <Typography>Total Clicks: {page.totalClicks}</Typography>
                            <Button
                                href={page.viewLink}
                                target="_blank"
                                endIcon={<OpenInNewIcon />}
                                disabled={!page.published}
                            >
                                Open Page
                            </Button>
                        </Stack>
                    </Box>
                </Toolbar>
                <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
                    <Tab label="Hotspot" />
                    <Tab label="Links" />
                    <Tab label="Print" />
                </Tabs>
            </AppBar>
            <Box sx={{ overflow: 'hidden' }}>
                {tab === 0 && (
                    <Hotspot
                        html={page.compiledHtml}
                        itemLinks={page.links}
                        type="page"
                    />
                )}
                {tab === 1 && (
                    <Links
                        id={page.id}
                        allLinks={page.links}
                        type="page"
                        linkId={null}
                    />
                )}
                {tab === 2 && (
                    <>
                        <Container maxWidth="lg" sx={{ marginTop: 3, paddingBottom: 3 }}>
                            <Paper sx={{ padding: 3 }}>
                                <Alert severity="info">
                                    <AlertTitle>Printing guidance</AlertTitle>
                                    <Typography variant="body2" sx={{ marginBottom: 0.5 }}>
                                        For the best visual experience, turn on 'Background graphics' in your browser's print dialog.
                                    </Typography>
                                    <Typography variant="body2">
                                        In Chrome and Edge, this option can be found in 'More settings'. In Firefox and Safari this option is called 'Print backgrounds'.
                                    </Typography>
                                </Alert>
                                <Button
                                    onClick={() => setShowPrintDialog(true)}
                                    color="secondary"
                                    sx={{ marginTop: 2 }}
                                >
                                    Generate Manager's Report
                                </Button>
                            </Paper>
                        </Container>
                        <Dialog
                            fullScreen
                            open={showPrintDialog}
                            onClose={() => setShowPrintDialog(false)}
                            TransitionComponent={Transition}
                            sx={printStyleOverrides}
                        >
                            <AppBar sx={{ position: 'relative', displayPrint: 'none' }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => setShowPrintDialog(false)}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        Manager's Report: {page.title}
                                    </Typography>
                                    <Button onClick={() => window.print()}>
                                        Print
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <Paper elevation={0} square>
                                <Container maxWidth="lg">
                                    <Box sx={{ ...printStyleCover, ...printStyleBreakAfter }}>
                                        <Box sx={{ paddingBottom: 4 }}>
                                            {logo ? (
                                                <img src={logo} style={{ maxWidth: 400, height: 'auto' }} />
                                            ) : (
                                                <Typography variant="h3">
                                                    {company}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Typography variant="h2">
                                            Landing Page Report
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ paddingBottom: 0, paddingTop: 2 }}
                                        >
                                            {page.name}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ paddingBottom: 2, paddingTop: 0 }}
                                        >
                                            Title: {page.title}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ paddingBottom: 1, paddingTop: 1 }}
                                        >
                                            Total Opens: {page.totalOpens}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{ paddingBottom: 1, paddingTop: 1 }}
                                        >
                                            Total Clicks: {page.totalClicks}
                                        </Typography>
                                        <Typography variant="body1">
                                            {page.published ? `Published: ${format((new Date(page.publishedDateTime)), 'dd/MM/yyyy HH:mm')}` : 'Draft'}
                                        </Typography>
                                        <Typography variant="body1">
                                            Report created: {format((new Date()), 'dd/MM/yyyy HH:mm')}
                                        </Typography>
                                    </Box>
                                    <Box sx={printStyleSection}>
                                        <Typography variant="h5">
                                            Hotspot
                                        </Typography>
                                        <Hotspot
                                            html={page.compiledHtml}
                                            itemLinks={page.links}
                                            type="page"
                                        />
                                    </Box>
                                    <Box sx={printStyleSection}>
                                        <Typography variant="h5">
                                            Link summary table
                                        </Typography>
                                        <Links
                                            id={page.id}
                                            linkId={null}
                                            parentIsLoading={isLoading}
                                            allLinks={page.links}
                                            type="page"
                                            isPrint={true}
                                        />
                                    </Box>
                                </Container>
                            </Paper>
                        </Dialog>
                    </>
                )}
            </Box>
        </>
    );
};

export default Page;