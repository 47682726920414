/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useAccount from '../hooks/useAccount';
import { addDays, addYears, format } from 'date-fns';
import DashboardOverview from '../startScreenComponents/DashboardOverview';
import useApi from '../hooks/useApi';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import NewsFeed from '../startScreenComponents/NewsFeed';
import AuthAndAdminStatus from '../startScreenComponents/AuthAndAdminStatus';
import ContentGraph from '../startScreenComponents/ContentGraph';
import YouTubeCarousel from '../startScreenComponents/YoutubeCarousel';
import ContactSupport from '../startScreenComponents/ContactSupport';

const Start = ({ handleHelpClick }) => {
    const { user, account, smtpModified, hasCustomSmtpConfiguration } = useAccount();
    const { handleGet } = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [alertMessages, setAlertMessages] = useState<{ severity: 'error' | 'warning' | 'info'; message: string }[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [accountInfo, setAccountInfo] = useState();
    const [dialogMessage, setDialogMessage] = useState('');

    const handleFetchAccount = async () => {
        const response = await handleGet('account');

        if (!response.ok) {
            return;
        }

        const data = await response.json();

        setAccountInfo(data);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        const expiryDate = new Date();
        expiryDate.setHours(23, 59, 59, 999);

        localStorage.setItem(`dialogDismissedExpiry_${account.id}`, expiryDate.getTime().toString());
    };

    const getGreeting = () => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            return 'Good Morning';
        }
        else if (currentHour < 18) {
            return 'Good Afternoon';
        }
        else {
            return 'Good Evening';
        }
    };

    useEffect(() => {
        let alerts = [];

        if (hasCustomSmtpConfiguration && smtpModified) {
            if (new Date() > addYears(new Date(smtpModified), 1)) {
                alerts.push({
                    severity: 'warning',
                    message: `Your Trusted Delivery SMTP password was set on ${format(new Date(smtpModified), 'dd/MM/yyyy')}. It may have expired.`
                });
            }
            else if (addDays(new Date(), 7) > addYears(new Date(smtpModified), 1)) {
                alerts.push({
                    severity: 'warning',
                    message: `Your Trusted Delivery SMTP password was set on ${format(new Date(smtpModified), 'dd/MM/yyyy')}. It may be due to expire soon.`
                });
            }
        }

        if (account.licence.lifetimeInMonths === 12) {
            const renewalDate = new Date(account.maxRenewalDate);
            const today = new Date();
            const timeDifference = renewalDate.getTime() - today.getTime();
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            const lastDismissed = parseInt(localStorage.getItem(`dialogDismissedExpiry_${account.id}`) || '0');
            const shouldOpenDialog = new Date().getTime() > lastDismissed;

            if (daysLeft <= 1) {
                const message = 'Your current NewZapp licence is due to expire tomorrow. We have not yet received payment for your renewal. Without payment your account will not be accessible after today.';

                alerts.push({
                    severity: 'error',
                    message
                });

                setOpenDialog(shouldOpenDialog);
                setDialogMessage(message);
            }
            else if (daysLeft <= 7) {
                const message = `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}. We have not yet received payment for your renewal. Please ensure your renewal payment is received by your renewal date or you will not be able to access the platform.`;

                alerts.push({
                    severity: 'error',
                    message
                });

                setOpenDialog(shouldOpenDialog);
                setDialogMessage(message);
            }
            else if (daysLeft <= 14) {
                alerts.push({
                    severity: 'warning',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}. We have not yet received payment for your renewal. Please ensure your renewal payment is received by your renewal date or you will not be able to access the platform.`
                });
            }
            else if (daysLeft <= 30) {
                alerts.push({
                    severity: 'warning',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}, please ensure your renewal payment is received by this date.`
                });
            }
            else if (daysLeft <= 60) {
                alerts.push({
                    severity: 'info',
                    message: `Your current NewZapp licence is due to expire on ${renewalDate.toDateString()}, your account manager will contact you to discuss your requirements.`
                });
            }

            setAlertMessages(alerts);
        }
    }, [account]);

    useEffect(() => {
        const handleInit = async () => {
            await handleFetchAccount();
            setIsLoading(false);

            if (!user.userTours.includes('start-screen-tour')) {
                handleHelpClick();
            }
        };

        handleInit();
    }, []);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }

    return (
        <Box sx={{ px: 3, pb: 2 }}>
            {alertMessages.length > 0 && alertMessages.map(({ message, severity }, i) => (
                <Alert key={i} sx={{ mt: 2 }} variant="filled" severity={severity}>
                    {message}
                </Alert>
            ))}

            <List sx={{ p: 0 }}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                width: 50,
                                height: 50
                            }}
                            alt={user.name}
                            src={user.avatarURL}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ ml: 1 }}
                        primary={`${getGreeting()} ${user.name}`}
                        secondary={user.emailAddress}
                        primaryTypographyProps={{ variant: 'h6' }}
                        secondaryTypographyProps={{ variant: 'subtitle1' }}
                    />
                </ListItem>
            </List>

            <Grid container spacing={1}>
                <Grid xs={12} md={12} xl={8}>
                    <>
                        <Grid container columnSpacing={1}>
                            <Grid xs={12} md={6} className="news-feed">
                                <NewsFeed hasCustomSmtpConfiguration={hasCustomSmtpConfiguration} />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <DashboardOverview accountInfo={accountInfo} />
                            </Grid>
                            <Grid xl={12} sx={{ display: { xs: 'none', xl: 'block' } }} className="youtube-carousel">
                                <YouTubeCarousel />
                            </Grid>
                        </Grid>
                    </>
                </Grid>

                <Grid xs={12} md={12} xl={4}>
                    <>
                        <Grid container spacing={1}>
                            <Grid xs={12}>
                                <AuthAndAdminStatus accountInfo={accountInfo} />
                            </Grid>
                            <Grid xs={12} className="content-graph">
                                <ContentGraph />
                            </Grid>
                            <Grid xl={12} sx={{ display: { xs: 'none', xl: 'block' } }} className="contact-support">
                                <ContactSupport />
                            </Grid>
                        </Grid>
                    </>
                </Grid>

                <Grid xs={12} sx={{ display: { xs: 'block', xl: 'none' } }} className="youtube-carousel">
                    <YouTubeCarousel />
                </Grid>
                <Grid xs={12} sx={{ display: { xs: 'block', xl: 'none' } }} className="contact-support">
                    <ContactSupport />
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Licence Expiry Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Start;