import React, { useState } from 'react';
import NavigationContainer from '../navigation/NavigationContainer';
import GroupsIcon from '@mui/icons-material/Groups';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '../tabs/Tab';
import Tabs from '../tabs/Tabs';
import NavigationItem from '../contacts/NavigationItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import Collapse from '@mui/material/Collapse';
import CreateGroupDialog from '../contacts/dialogs/CreateGroupDialog';
import EditGroupDialog from '../contacts/dialogs/EditGroupDialog';
import DeleteGroupDialog from '../contacts/dialogs/DeleteGroupDialog';
import EditSegmentDialog from './dialogs/EditSegmentDialog';
import CreateSegmentDialog from './dialogs/CreateSegmentDialog';
import DeleteSegmentDialog from '../contacts/dialogs/DeleteSegmentDialog';
import ArchiveGroupConfirmationDialog from '../contacts/dialogs/ArchiveGroupConfirmationDialog';
import PseudoGroup from '../contacts/PseudoGroup';
import GroupsFilters from '../contacts/GroupsFilters';
import SegmentsFilters from '../contacts/SegmentsFilters';

const ActionsMenu = ({ onEdit, onDelete, onArchive, isArchiveGroup, type }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEdit();
        handleClose();
    };

    const handleDelete = () => {
        onDelete();
        handleClose();
    };

    const handleArchive = () => {
        onArchive();
        handleClose();
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                edge="end"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                {(type === 'group' && onArchive && !isArchiveGroup) && <MenuItem onClick={handleArchive}>Archive</MenuItem>}
            </Menu>
        </>
    );
};

const ListItems = ({ items, type, selectedItem, onSetSelectedItem, onEdit, onDelete, onFilterByTag = null, onArchive = null }) => {
    if (items?.length > 0) {
        return items.map(item => (
            <NavigationItem
                key={item.id}
                item={item}
                type={type}
                selected={selectedItem?.id === item.id}
                onSetSelectedItem={onSetSelectedItem}
                onFilterByTag={onFilterByTag}
                action={(
                    <ActionsMenu
                        disabled={!item.id}
                        onEdit={() => onEdit(item)}
                        onDelete={() => onDelete(item)}
                        onArchive={() => onArchive(item)}
                        isArchiveGroup={item?.type === 'archive'}
                        type={type}
                    />
                )}
            />
        ));
    }

    return (
        <Typography
            variant="body2"
            sx={{ padding: 2, textAlign: 'center' }}
        >
            No {type}s found
        </Typography>
    );
};

const ContactsNavigation = props => {
    const {
        drawerWidth,
        tab,
        setTab,
        selectedGroup,
        setSelectedGroup,
        selectedSegment,
        setSelectedSegment,
        selectedPseudoGroup,
        setSelectedPseudoGroup,
        isLoadingNavigation,
        onCreateSegment,
        allContactsCount,
        suppressedCount,
        ungroupedCount,
        onFilterByTag,
        groupsSearchValue,
        groupsTempSearchValue,
        setGroupsTempSearchValue,
        segmentsTempSearchValue,
        setSegmentsTempSearchValue,
        setGroupsSearchValue,
        setSegmentsSearchValue,
        onCreateGroup,
        onUpdateGroup,
        onDeleteSegment,
        onDeleteGroup,
        onUpdateSegmentDetails,
        groups,
        segments,
        groupsSort,
        setGroupsSort,
        groupType,
        setGroupType,
        segmentsSort,
        setSegmentsSort,
        filterTagIds,
        onLoadSuppressedCount,
        onLoadUngroupedCount
    } = props;

    const [dialog, setDialog] = useState(null);

    return (
        <>
            <NavigationContainer width={drawerWidth}>
                <AppBar
                    position="sticky"
                    color="inherit"
                >
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => setTab(tab)}
                        variant="fullWidth"
                    >
                        <Tab label="Groups" />
                        <Tab label="Segments" />
                    </Tabs>
                    <Toolbar sx={{ px: 2 }} disableGutters>
                        {tab === 0 && (
                            <GroupsFilters
                                openCreateGroupDialog={() => setDialog(
                                    <CreateGroupDialog
                                        onSubmit={group => {
                                            onCreateGroup(group);
                                            setDialog(null);
                                        }}
                                        onClose={() => setDialog(null)}
                                    />
                                )}
                                groupsSort={groupsSort}
                                setGroupsSort={setGroupsSort}
                                groupType={groupType}
                                setGroupType={setGroupType}
                                groupsTempSearchValue={groupsTempSearchValue}
                                setGroupsTempSearchValue={setGroupsTempSearchValue}
                                setGroupsSearchValue={setGroupsSearchValue}
                                filterTagIds={filterTagIds}
                                onFilterByTag={onFilterByTag}
                                popperGutters={2}
                                showArchive={true}
                            />
                        )}
                        {tab === 1 && (
                            <SegmentsFilters
                                openCreateSegmentDialog={() => setDialog(
                                    <CreateSegmentDialog
                                        onSubmit={segment => onCreateSegment(segment)}
                                        onClose={() => setDialog(null)}
                                        allContactsCount={allContactsCount}
                                    />
                                )}
                                segmentsSort={segmentsSort}
                                setSegmentsSort={setSegmentsSort}
                                segmentsTempSearchValue={segmentsTempSearchValue}
                                setSegmentsTempSearchValue={setSegmentsTempSearchValue}
                                setSegmentsSearchValue={setSegmentsSearchValue}
                                filterTagIds={filterTagIds}
                                onFilterByTag={onFilterByTag}
                                popperGutters={2}
                            />
                        )}
                    </Toolbar>
                </AppBar>
                <Collapse in={isLoadingNavigation}>
                    <LinearProgress />
                </Collapse>
                {tab === 0 && (
                    <List component="nav" aria-label="Groups Navigation" disablePadding>
                        <Collapse in={groupsSearchValue === '' && filterTagIds.length === 0}>
                            <PseudoGroup
                                text="All Contacts"
                                count={allContactsCount}
                                icon={<GroupsIcon fontSize="small" aria-label="All Contacts" />}
                                setSelectedItem={() => setSelectedPseudoGroup('allContacts')}
                                selectedItem={selectedPseudoGroup === 'allContacts'}
                            />
                            <PseudoGroup
                                text="Suppressed"
                                icon={<DoNotDisturbAltIcon fontSize="small" aria-label="Suppressed" />}
                                count={suppressedCount}
                                setSelectedItem={() => setSelectedPseudoGroup('suppressed')}
                                selectedItem={selectedPseudoGroup === 'suppressed'}
                                onLoad={onLoadSuppressedCount}
                            />
                            <PseudoGroup
                                text="Ungrouped"
                                icon={<AccessibilityIcon fontSize="small" aria-label="Ungrouped" />}
                                count={ungroupedCount}
                                setSelectedItem={() => setSelectedPseudoGroup('ungrouped')}
                                selectedItem={selectedPseudoGroup === 'ungrouped'}
                                lastItem
                                onLoad={onLoadUngroupedCount}
                            />
                        </Collapse>
                        <ListItems
                            items={groups}
                            type="group"
                            onEdit={group => setDialog(
                                <EditGroupDialog
                                    group={group}
                                    onSubmit={group => onUpdateGroup(group)}
                                    onClose={() => setDialog(null)}
                                />
                            )}
                            onDelete={group => setDialog(
                                <DeleteGroupDialog
                                    group={group}
                                    onSubmit={group => onDeleteGroup(group)}
                                    onClose={() => setDialog(null)}
                                />
                            )}
                            selectedItem={selectedGroup}
                            onSetSelectedItem={setSelectedGroup}
                            onFilterByTag={onFilterByTag}
                            onArchive={group => setDialog(
                                <ArchiveGroupConfirmationDialog
                                    group={group}
                                    onSubmit={group => onUpdateGroup(group)}
                                    onClose={() => setDialog(null)}
                                />
                            )}
                        />
                    </List>
                )}
                {tab === 1 && (
                    <List component="nav" aria-label="Segments Navigation" disablePadding>
                        <ListItems
                            items={segments}
                            type="segment"
                            onEdit={segment => setDialog(
                                <EditSegmentDialog
                                    segment={segment}
                                    onSubmit={segment => onUpdateSegmentDetails(segment)}
                                    onClose={() => setDialog(null)}
                                />
                            )}
                            onDelete={segment => setDialog(
                                <DeleteSegmentDialog
                                    segment={segment}
                                    onSubmit={segment => onDeleteSegment(segment)}
                                    onClose={() => setDialog(null)}
                                />
                            )}
                            selectedItem={selectedSegment}
                            onSetSelectedItem={setSelectedSegment}
                            onFilterByTag={onFilterByTag}
                        />
                    </List>
                )}
            </NavigationContainer>
            {dialog}
        </>
    );
};

export default ContactsNavigation;