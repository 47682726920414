import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import 'whatwg-fetch';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AccountProvider } from './accountContext/AccountContext';
import { SnackbarProvider } from './snackbarContext/SnackbarContext';
import config from './config';
import NotificationsProvider from './notifications/NotificationsProvider';
import { DialogProvider } from './dialogContext/DialogContext';
import { Switch, Route } from 'react-router-dom';
import SurveyView from '../src/survey/SurveyView';
import { msalInstance } from './msalConfig';
import { MsalProvider } from '@azure/msal-react';

const { AUTH0_DOMAIN: domain, AUTH0_CLIENT_ID: clientId, AUTH0_AUDIENCE: audience, AUTH0_SCOPE: scope } = config;

const jsx = (
    <SnackbarProvider>
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                'redirect_uri': window.location.origin,
                audience,
                scope
            }}
        >
            <DialogProvider>
                <HashRouter>
                    <Switch>
                        <Route path="/surveys/:id/:verifierId/:logId?/:contactId?">
                            <SurveyView />
                        </Route>
                        <Route path="/">
                            <AccountProvider>
                                <NotificationsProvider>
                                    <MsalProvider instance={msalInstance}>
                                        <App />
                                    </MsalProvider>
                                </NotificationsProvider>
                            </AccountProvider>
                        </Route>
                    </Switch>
                </HashRouter>
            </DialogProvider>
        </Auth0Provider>
    </SnackbarProvider>
);

const root = document.getElementById('root');

ReactDOM.render(jsx, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
